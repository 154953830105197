import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as portfolioStyles from "./portfolio.module.css"


const PortfolioTemplate = ({ data, location }) => {
  const post = data.markdownRemark
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const { previous, next } = data

  return (
    <Layout location={location} title={siteTitle}>
      <Seo
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
      <article
        itemScope
        itemType="http://schema.org/Article"
      >
        <header className={portfolioStyles.header}>
          <h1 itemProp="headline">Case study / {post.frontmatter.title}</h1>
          <h2 itemProp="subtitle">
            {post.frontmatter.timeline} &middot; {post.frontmatter.shorttitle}
           </h2>
          <p>{post.frontmatter.overview}</p>
        </header>
        <hr />
        <section
          dangerouslySetInnerHTML={{ __html: post.html }}
          itemProp="articleBody"
          className={portfolioStyles.body}
        />
        <hr />
      </article>
      <nav className={portfolioStyles.related}>
        <h3>Other projects:</h3>
        <p>
          {previous && (
            <Link to={ "/work" + previous.fields.slug} rel="prev">
               {previous.frontmatter.title}
            </Link>
          )}
        </p>
        <p>
          {next && (
            <Link to={ "/work" + next.fields.slug} rel="next">
              {next.frontmatter.title}
            </Link>
          )}
        </p>
      </nav>
    </Layout>
  )
}

export default PortfolioTemplate

export const pageQuery = graphql`
  query PortfolioBySlug(
    $id: String!
    $previousPostId: String
    $nextPostId: String
  ) {
    site {
      siteMetadata {
        title
      }
    }
    markdownRemark(id: { eq: $id }) {
      id
      excerpt(pruneLength: 160)
      html
      frontmatter {
        title
        description
        timeline
        shorttitle
        overview
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
  }
`
